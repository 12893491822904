@use 'sass:math';

/* ==========================================================================
   #PERSON TILE
   ========================================================================== */

.c-person-tile {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.c-person-tile--letter {
  grid-column-start: 1;
  grid-column-end: 2;
}

.c-person-tile[href]::before {
  content: '';
  display: block;
  position: absolute;
  left: -16px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  transition: opacity 0.3s ease;
  z-index: -1;
  opacity: 0;
  background-color: var(--brand-color);
}

.c-person-tile[href]:hover {
  color: var(--person-text-color);
}

.c-person-tile[href]:hover .c-person-tile__title {
  color: inherit;
}

.c-person-tile[href]:hover::before {
  opacity: 1;
}

.c-person-tile__letter {
  color: var(--main-text-color);
  text-transform: uppercase;
  line-height: 1;
  margin-top: -1em;
}

@include bp(medium) {
  .c-person-tile[href]::before {
    left: -32px;
    top: -32px;
    right: -32px;
    bottom: -32px;
  }
}
