/* ==========================================================================
   #SVG
   ========================================================================== */

svg {
  fill: currentColor;
  transition: fill $transition-slow-in ease, stroke $transition-slow-in ease;
}

use,
svg {
  transform-origin: center center;
}
