/* ==========================================================================
   #SUBMIT
   ========================================================================== */

.o-submit {
  @include button-primary;

  min-width: 120px;
  text-align: center;
}

.o-submit:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.o-submit--secondary {
  @include button-secondary;
}

@include bp(large) {
  .o-submit {
    min-width: 158px;
  }
}
