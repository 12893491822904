/* ==========================================================================
   #LINK
   ========================================================================== */

.o-links-brand a,
.o-link-brand {
  @include link-active-hover-click {
    color: var(--brand-color);
  }
}

.o-links-primary a,
.o-link-primary {
  color: var(--brand-color);
}
