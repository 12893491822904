.has-brand-color {
  color: var(--brand-color);
}

.has-brand-darker-color {
  color: var(--brand-darker-color);
}

.has-brand-darkest-color {
  color: var(--brand-darkest-color);
}

.has-gray-color {
  color: #8d8d8d;
}

.has-black-color {
  color: $color-black;
}

.has-white-color {
  color: $color-white;
}
