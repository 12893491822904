@use 'sass:math';

/* ==========================================================================
   #RESOURCE
   ========================================================================== */

.c-resource {
  position: relative;
}

.c-resource__author {
  margin-top: 50px;
  margin-bottom: 50px;
}

@include bp-down(xlarge) {
  .gutenberg-blocks .c-resource__author.c-resource__author {
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - #{$wrapper-padding-small * 2});
    max-width: $wrapper-width-small;
  }
}

@include bp(xlarge) {
  .c-resource {
    margin-top: 90px;
    margin-bottom: 90px;
  }

  .c-resource__author {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: auto;
    margin-bottom: 0;
  }

  .c-resource__title {
    margin-bottom: 70px;
  }

  .gutenberg-blocks .c-resource__author.c-resource__author.c-resource__author {
    @include wrapper-left($wrapper-width);

    width: calc(
      (100% - #{$wrapper-width-small}) / 2 - #{2 * $wrapper-padding-small}
    );
    max-width: 283px;
  }

  .gutenberg-blocks .c-resource__title.c-resource__title {
    padding-right: calc(
      (100% - #{$wrapper-width-small}) / 2 - #{$wrapper-padding-small}
    );
  }
}
