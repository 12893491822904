@use 'sass:math';

/* ==========================================================================
   #ANNOTATION
   ========================================================================== */

.c-annotation {
  @include big-space-block;
}

.c-annotation__title {
  $padding: 30px;
  $padding-bottom: 27px;

  border: 2px solid var(--brand-darkest-color);
  margin-bottom: 30px;
  color: var(--brand-darkest-color);
  font-weight: 700;
  padding: $padding $padding $padding-bottom $padding;
  display: flex;
  justify-content: space-between;
  font-size: inherit;
  width: 100%;
  text-align: left;
  align-items: center;
}

.c-annotation__items {
  overflow: hidden;
  transition: max-height 0.3s ease;
  max-height: 0;
}

.c-annotation.is-open .c-annotation__items {
  max-height: none;
}

.c-annotation__items-list {
  margin-left: calc(30px + 1em);
  margin-right: 30px;
  margin-top: 30px;
}

.c-annotation__item a {
  color: inherit;
  text-decoration: underline;
}

.c-annotation__item a:hover {
  color: var(--brand-color);
}

.c-annotation__title-icon {
  display: block;
  width: (math.div(26, 22) * 1em);
  height: (math.div(26, 22) * 1em);
  margin-top: -3px;
  margin-left: 10px;
  margin-right: 0;
  position: relative;
  transition: transform $transition-fast-in;
}

.c-annotation__title-icon::after,
.c-annotation__title-icon::before {
  content: '';
  width: 100%;
  height: 0;
  display: block;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
}

.c-annotation__title-icon::after {
  transform: rotate(90deg);
}

.c-annotation.is-open .c-annotation__title-icon {
  transform: rotate(45deg);
}

.c-annotation__item-more {
  color: var(--brand-color);
}

@include bp(medium) {
  .c-annotation__items-list {
    margin-top: 60px;
  }
}
