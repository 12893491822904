@mixin button-primary {
  color: var(--main-bg-color);
  background-color: var(--brand-color);
  border: 2px solid transparent;
  padding: 17px 22px 13px;
  letter-spacing: 0;
  font-weight: 700;
}

@mixin button-secondary {
  color: var(--brand-color);
  background-color: var(--main-bg-color);
  border: 2px solid var(--brand-color);
  padding: 17px 22px 13px;
  letter-spacing: 0;
  font-weight: 700;
}
