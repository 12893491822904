.is-style-program.is-style-program td,
.is-style-program.is-style-program th {
  border: none;
  vertical-align: top;
  padding: 0 0 0.8em 0;
}

.is-style-program.is-style-program td:first-child,
.is-style-program.is-style-program th:first-child {
  text-align: right;
  white-space: nowrap;
  padding-right: 1.2em;
  width: 150px;
}

.is-style-program .has-gray-color {
  font-size: 13px;
  display: block;
  letter-spacing: 0;
}

@include bp-down(small) {
  .is-style-program table,
  .is-style-program td,
  .is-style-program tr {
    display: block;
  }

  .is-style-program.is-style-program td:first-child,
  .is-style-program.is-style-program th:first-child {
    text-align: left;
    padding-bottom: 0;
  }
}
