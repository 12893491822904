/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: var(--footer-bg-color);
  color: var(--alt-text-color);
  padding: 25px 0;
  position: relative;
}

.c-footer__menu-nav {
  letter-spacing: 0;
  margin-bottom: 45px;
}

.c-footer__menu-item {
  margin-bottom: 15px;
}

.c-footer__menu-link {
  text-transform: uppercase;
  font-weight: 700;
}

.c-footer__newsletter {
  max-width: 480px;
  margin-bottom: 45px;
}

.c-footer__newsletter-title {
  line-height: 1.2;
}

.c-footer__newsletter-text {
  margin-bottom: 1em;
  color: rgba($color-white, 0.8);
  max-width: 420px;
}

.c-footer__newsletter-inputs {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  letter-spacing: 0;
}

.c-footer__newsletter-email {
  font-size: 18px;
  background-color: rgba(#797979, 0.28);
  color: #c9c9c9;
  line-height: 1;
  padding: 17px 18px 16px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(100% - 160px);
  min-width: 0;
}

.c-footer__newsletter-submit {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 160px;
}

.c-footer__icon {
  position: absolute;
  right: 0;
  bottom: 20px;
}

.c-footer__social {
  display: flex;
  align-items: flex-start;
  color: rgba($color-white, 0.5);
  max-width: calc(100% - 20px);
  letter-spacing: 0;
  margin-bottom: 15px;
}

.c-footer__social-link {
  color: $color-white;
  padding: 10px 15px;
  margin-top: -12px;
  margin-bottom: -10px;
  align-self: center;
}

.c-footer__social-label {
  margin-right: auto;
}

.c-footer__social-icon {
  display: block;
}

.c-footer__media {
  margin-top: 45px;
  margin-bottom: 45px;
}

.c-footer__menu-bottom {
  color: rgba($color-white, 0.5);
  margin-left: -30px;
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 0;
}

.c-footer__menu-bottom-link {
  margin-left: 30px;
  display: inline-block;
  margin-bottom: 15px;
}

.c-footer__url {
  color: rgba($color-white, 0.5);
  text-align: right;
  padding-right: 40px;
  margin-bottom: -5px;
  letter-spacing: 0;
}

.c-footer__newsletter-agree {
  display: flex;
  margin-top: 20px;
}

.c-footer__newsletter-agree-input {
  @include checkbox;
}

.c-footer__newsletter-form.is-error
  .c-footer__newsletter-agree-input:not(:checked) {
  color: $color-warning;
}

.c-footer__newsletter-agree-text {
  font-size: 14px;
  opacity: 0.8;
}

.c-footer__newsletter-agree-text a {
  text-decoration: underline;
}

.c-footer__newsletter-agree-text > :last-child {
  margin-bottom: 0;
}

.c-footer__newsletter-warning {
  display: none;
  align-items: center;
  color: $color-warning;
  margin-top: 15px;
}

.c-footer__newsletter-form.is-error .c-footer__newsletter-warning {
  display: flex;
}

.c-footer__newsletter-warning-icon {
  margin-right: 11px;
  margin-top: -2px;
}

.c-footer__newsletter-message {
  margin-top: 15px;
  color: var(--brand-color);
}

.c-footer__newsletter-message:empty {
  display: none;
}

.c-footer__newsletter-form.is-submitted .c-footer__newsletter-inputs,
.c-footer__newsletter-form.is-submitted .c-footer__newsletter-agree,
.c-footer__newsletter-form.is-submitted .c-footer__newsletter-warning {
  display: none;
}

@include bp(small) {
  .c-footer__social-label {
    margin-right: 20px;
  }
}

@include bp-down(medium) {
  .c-footer__menu-link {
    color: rgba($color-white, 0.8);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
  }

  .c-footer__menu-link svg {
    color: $color-white;
  }

  .c-footer__menu-subnav {
    overflow: hidden;
    max-height: 0;
    transition: max-height $transition-slow-in ease-in-out;
  }

  .c-footer__menu-item.is-active .c-footer__menu-subnav {
    max-height: 300px;
  }

  .c-footer__menu-bottom-link {
    display: block;
  }
}

@include bp(medium) {
  .c-footer {
    padding: 45px 0;
  }

  .c-footer__menu-nav {
    display: flex;
  }

  .c-footer__chevron {
    display: none;
  }

  .c-footer__menu {
    max-width: 720px;
  }

  .c-footer__icon {
    bottom: 40px;
  }

  .c-footer__bottom {
    display: flex;
    align-items: baseline;
  }

  .c-footer__menu-bottom {
    margin-left: 0;
  }

  .c-footer__menu-item {
    width: calc(100% / 3);
  }

  .c-footer__social {
    margin-bottom: 0;
  }
}

@include bp(large) {
  .c-footer {
    padding: 120px 0 90px;
  }

  .c-footer__inner {
    display: flex;
    flex-wrap: wrap;
  }

  .c-footer__menu-bottom {
    margin-top: 0;
    margin-bottom: 0;
  }

  .c-footer__newsletter {
    margin-left: auto;
    flex-shrink: 0;
    width: 40%;
  }

  .c-footer__menu {
    flex-grow: 1;
    margin-right: 60px;
  }

  .c-footer__url {
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }

  .c-footer__icon {
    bottom: 80px;
  }
}

@include bp(xlarge) {
  .c-footer__media {
    margin-top: 0;
    margin-bottom: 90px;
  }

  .c-footer__newsletter {
    margin-bottom: 90px;
  }

  .c-footer__menu-nav {
    margin-bottom: 90px;
  }

  .c-footer__url {
    margin-right: 60px;
  }
}

@include bp(full) {
  .c-footer__newsletter {
    margin-right: 90px;
  }
}

@include bp(1550px) {
  .c-footer__icon {
    right: calc((100vw - #{$wrapper-width}) / 2 - 40px);
  }
}
