/* ==========================================================================
   #OVERLAY
   ========================================================================== */

.c-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overscroll-behavior: none;
  z-index: 10;
  background-color: var(--main-bg-color);
  transition: opacity $transition-fast-in, visibility $transition-fast-in;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

.c-overlay--work-gallery {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  top: calc(var(--header-height, 0) + var(--admin-bar-height, 0));
  height: calc(100% - var(--header-height, 0) - var(--admin-bar-height, 0));
}

.c-overlay.is-active {
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
}

.c-overlay__header {
  margin-top: var(--admin-bar-height, 0);
}

.c-overlay__header-inner {
  height: 71px;
  display: flex;
  align-items: center;
}

.c-overlay__logo {
  width: 94px;
  height: 36px;
  display: block;
}

.c-overlay__close {
  margin-left: auto;
  color: var(--brand-color);
  padding: 10px;
  margin-right: -10px;
  position: absolute;
  right: 0;
  top: 15px;
}

.c-overlay__close-icon {
  display: block;
}

.c-overlay__close-wrapper {
  top: var(--admin-bar-height, 0);
  left: 0;
  position: sticky;
  z-index: 1;
}
