/* ==========================================================================
   #FIT
   ========================================================================== */

.u-fit-cover {
  object-fit: cover;
}

.u-fit-contain {
  object-fit: contain;
}

.u-fit-scale-down {
  object-fit: scale-down;
}
