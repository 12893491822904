@use 'sass:math';

/* ==========================================================================
   #WORK
   ========================================================================== */

.c-work {
  padding-top: 20px;
  padding-bottom: 20px;
}

.c-work__header {
  margin-bottom: 22px;
}

.c-work__meta {
  margin-top: 1em;
}

.c-work__meta:not(.show-all) .c-work__meta-authors > *:nth-child(n + 2) {
  display: none;
}

.c-work__title {
  margin-bottom: 0;
}

.c-work__footer {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.c-work__all-works:not(:first-child) {
  margin-top: 45px;
  display: inline-flex;
}

.c-work__more {
  position: relative;
}

.c-work__authors {
  margin-top: 24px;
  margin-bottom: 36px;
  display: flex;
  align-items: flex-start;
}

.c-work__authors-label {
  margin-right: 20px;
}

.c-work__authors-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.c-work__more-slider {
  padding-top: 10px;
  margin-top: -10px;
}

.c-work__more-slide {
  width: 255px;
  height: 128px;
  margin-left: 10px;
  margin-right: 10px;
}

.c-work__more-slide-award {
  position: absolute;
  right: -10px;
  top: -10px;
}

@include bp(medium) {
  .c-work__authors-label {
    margin-right: 24px;
  }

  .c-work__authors-list {
    flex-direction: row;
    gap: 24px;
  }
}

@include bp(large) {
  .c-work__header {
    display: flex;
    align-items: center;
  }

  .c-work__title {
    margin-right: 1em;
    max-width: 70%;
  }

  .c-work__meta {
    margin-top: 0;
    text-align: right;
    margin-left: auto;
  }

  .c-work__footer {
    margin-top: 45px;
    margin-bottom: 90px;
  }
}

@include bp(xlarge) {
  .c-work__title {
    max-width: 63%;
  }
}
