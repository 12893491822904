@use 'sass:math';

/* ==========================================================================
   #REGISTER
   ========================================================================== */

@keyframes register-loading {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  33.33% {
    transform: scale(1);
    opacity: 1;
  }

  66.66% {
    transform: scale(0.428);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.c-register {
  position: relative;
  margin-top: 32px;
  margin-bottom: 32px;
}

.c-register__closed-message {
  margin-top: 32px;
  margin-bottom: 32px;
}

.c-register__title {
  margin-bottom: 40px;
}

.c-register__steps {
  display: flex;
  gap: 17px;
  list-style-position: inside;
  margin-left: 0;
  margin-bottom: 32px;
}

.c-register__steps-step {
  opacity: 0.5;
  transition: opacity $transition-fast-in;
}

.c-register__steps-step.is-active {
  opacity: 1;
}

.c-register__label {
  margin-bottom: 1em;
}

.c-register__product-name {
  margin-bottom: 1em;
  width: 100%;
}

.c-register__product-row {
  margin-bottom: 32px;
  max-width: 430px;
}

.c-register__payment-info {
  max-width: 430px;
}

.c-register__product-price {
  font-size: 22px;
  float: right;
  margin-left: auto;
}

.c-register__form-footer {
  margin-top: 54px;
  display: flex;
  gap: 22px;
}

.c-register__form-footer > * {
  width: calc(50%);
  min-width: 0;
  max-width: 158px;
}

.c-register .o-checkbox {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.c-register .o-checkbox__label {
  flex-grow: 1;
  padding-top: 5px;
}

.c-register__page--2 .o-checkbox,
.c-register__page--2 .o-input {
  max-width: 315px;
}

.c-register__page--2 .o-checkbox {
  flex-wrap: nowrap;
  margin-bottom: 32px;
}

.c-register__page--2 .o-input {
  margin-bottom: 32px;
}

.c-register__page--2 .o-checkbox__label p:last-child {
  margin-bottom: 0;
}

.o-input__error {
  display: flex;
  color: var(--form-error-color);
  margin-top: 20px;
  padding-top: 5px;
}

.o-input__error-icon {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 11px;
  margin-top: -5px;
}

.c-register__agree .o-input__error {
  margin-left: -43px;
}

.c-register__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 40px;
}

.c-register__loading-oval {
  animation-name: register-loading;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  transform-origin: center center;
  transform-box: fill-box;
}

.c-register__loading-oval--2 {
  animation-delay: 0.5s;
}

.c-register__loading-oval--3 {
  animation-delay: 1s;
}

.c-register__message-error,
.c-register__message-no-room,
.c-register__message-fatal-error,
.c-register__overlay .c-register__title > :last-child,
.c-register__overlay.is-error .c-register__title > :first-child,
.c-register__overlay:not(.is-error) .c-register__messages-buttons,
.c-register__overlay.is-error .c-register__message-thanks,
.c-register__invoice-data:not(.is-active),
.c-register__overlay.is-error .c-register__loading {
  display: none;
}

.c-register__overlay.is-order-fatal-error .c-register__message-fatal-error,
.c-register__overlay.is-order-no-room .c-register__message-no-room,
.c-register__overlay.is-order-error .c-register__message-error,
.c-register__overlay.is-error .c-register__title > :last-child {
  display: block;
}

.c-register__page {
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow-y: hidden;

  &.is-active {
    position: relative;
    opacity: 1;
    visibility: visible;
    max-height: none;
    overflow-y: visible;
  }
}

.c-register__pages {
  position: relative;
}

.c-register__payment-form {
  display: none;
}

.c-register__invoice-note.c-register__invoice-note .o-input__error {
  display: flex;
  color: inherit;
  margin-bottom: 32px;
  max-width: 315px;
}

.c-register--en .c-register__invoice-collect {
  display: none;
}

@include bp(large) {
  .c-register__product-row,
  .c-register__title {
    margin-bottom: 64px;
  }

  .c-register__padding {
    margin-left: 320px;
  }

  .c-register__form-footer {
    margin-top: 120px;
  }

  .c-register__loading {
    padding-top: 150px;
    padding-bottom: 80px;
  }

  .c-register,
  .c-register__closed-message {
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .c-register__label {
    padding-left: 1em;
  }

  .c-register__page--2 .c-register__label {
    margin-bottom: 0;
    position: relative;
  }

  .c-register__page--2 .c-register__label > * {
    position: absolute;
    left: 1em;
    top: 0;
  }
}
