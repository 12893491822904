/* ==========================================================================
   #SELECT
   ========================================================================== */

.o-select {
  position: relative;
  letter-spacing: 0.01em;
  display: inline-block;
}

.o-select__input {
  appearance: none;
  display: block;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
  border: 2px solid currentColor;
  padding: 0.5em 2.5em 0.5em 1em;
  line-height: 1.5;
  width: 100%;
}

.o-select__input option {
  background-color: var(--main-bg-color);
}

.o-select__icon {
  position: absolute;
  right: 1em;
  top: 50%;
  margin-top: -3px;
}
