@use 'sass:math';

/* ==========================================================================
   #WORK AWARD
   ========================================================================== */

.c-work-award {
  border-radius: 50%;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: math.div(20, 12) * 1em;
  height: math.div(20, 12) * 1em;
  color: var(--person-text-color);
  background-color: var(--person-bg-color);
}

.c-work-award strong {
  font-weight: 900;
}

.c-work-award sup {
  font-size: 0.5em;
  margin-top: -0.2em;
}

.c-work-award svg {
  display: block;
}

.c-work-award__text {
  display: none;
}
