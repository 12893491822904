@use 'sass:math';

/* ==========================================================================
   #GALLERY
   ========================================================================== */

.c-gallery {
  padding: 20px 0;
}

.c-gallery__title {
  margin-bottom: 25px;
}
