@use 'sass:math';

/* ==========================================================================
   #PEOPLE
   ========================================================================== */

.c-people {
  @include big-space-block;
}

.c-people__items {
  max-width: 1100px;
  display: grid;
  row-gap: 1em;
  column-gap: 1em;
  grid-template-columns: minmax(auto, 230px) minmax(auto, 230px);
}

.c-people__item {
  color: $color-white;
  display: block;
  position: relative;
  max-width: 230px;
  width: 100%;
}

.c-people__item::before {
  content: '';
  display: block;
  padding-top: 100%;
}

/* stylelint-disable selector-no-qualifying-type */
a.c-people__item {
  @include link-active-hover {
    color: var(--person-text-color, var(--alt-text-color));

    .c-people__item-media::after {
      opacity: 1;
    }
  }
}
/* stylelint-enable selector-no-qualifying-type */

.c-people__item-media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-people__item-media::before {
  display: block;
  content: '';
  position: absolute;
  background: linear-gradient(to top, #000 0%, transparent 100%) repeat-x;
  height: math.div(62, 226) * 100%;
  width: 100%;
  bottom: 0;
  left: 0;
}

.c-people__item-media::after {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--person-bg-color, var(--brand-darker-color));
  opacity: 0;
  transition: opacity $transition-slow-in;
}

.c-people__item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.c-people__item-title {
  position: absolute;
  left: 17px;
  bottom: 13px;
  width: calc(100% - 34px);
  margin-bottom: 0;
  font-size: inherit;
  font-weight: 700;
  line-height: 1;
}

@include bp(medium) {
  .c-people__items {
    grid-template-columns: minmax(auto, 230px) minmax(auto, 230px) minmax(
        auto,
        230px
      );
    row-gap: 2em;
    column-gap: 2em;
  }
}

@include bp(large) {
  .c-people__items {
    grid-template-columns:
      minmax(auto, 230px) minmax(auto, 230px) minmax(auto, 230px)
      minmax(auto, 230px);
    row-gap: 60px;
    column-gap: 60px;
  }
}
