/* ==========================================================================
   #OVERLAY
   ========================================================================== */

.c-database__header {
  margin-top: 15px;
}

.c-database__subpages {
  letter-spacing: 0.02em;
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-bottom: 25px;
}

.c-database__subpage--active {
  color: var(--brand-color);
}

.c-database__title {
  letter-spacing: 0.01em;
  margin-bottom: 11px;
}

.c-database__filters {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
}

.c-database__content {
  margin-top: 32px;
}

@include bp(medium) {
  .c-database__filters {
    flex-direction: row;
    align-items: center;
    gap: 32px;
    justify-content: flex-end;
  }
}

@include bp(large) {
  .c-database__title {
    display: none;
  }

  .c-database__subpages {
    margin-bottom: 30px;
  }

  .c-database__header {
    margin-top: 5px;
  }
}
