/// * ========================================================================
//   #LINK
//   ======================================================================== */

@mixin link-active-hover-click($all_devices: false) {
  @if ($all_devices) {
    &.is-active,
    &:active {
      @content;
    }
  } @else {
    @at-root html.no-touch &:hover,
      &.is-active,
      &:active {
      @content;
    }
  }
}

@mixin link-active-hover() {
  @at-root html.no-touch &:hover,
    &:active {
    @content;
  }
}

@mixin link-hover() {
  @at-root html.no-touch &:hover {
    @content;
  }
}

@mixin link-active() {
  &:active {
    @content;
  }
}

@mixin link-click-hover($all_devices: false) {
  @if ($all_devices) {
    &:hover,
    &.is-active {
      @content;
    }
  } @else {
    @at-root html.no-touch &:hover,
      &.is-active {
      @content;
    }
  }
}

@mixin link-active-click() {
  &:active,
  &.is-active {
    @content;
  }
}

@mixin link-click() {
  &.is-active {
    @content;
  }
}
