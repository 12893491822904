/* ==========================================================================
   #HAMBURGER
   ========================================================================== */

.o-hamburger__wrapper {
  display: block;
  width: 29px;
  height: 26px;
  position: relative;
  line-height: 1;
  margin: auto;
}

.o-hamburger__bar {
  display: block;
  position: absolute;
  height: 2px;
  width: 19px;
  background-color: currentColor;
  opacity: 1;
  left: 0;
  transform: none;
  transition: transform 0.25s ease, width 0.25s;
  transform-origin: center center;
  border-radius: 1px;
}

.o-hamburger__bar:nth-child(1) {
  top: 0;
}

.o-hamburger__bar:nth-child(2),
.o-hamburger__bar:nth-child(3) {
  top: 12px;
  width: 100%;
  left: auto;
  right: 0;
}

.o-hamburger__bar:nth-child(4) {
  top: 24px;
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(1) {
  transform: scaleX(0) translateY(9px);
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(2) {
  transform: rotate(45deg) scaleX(1.24138);
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(3) {
  transform: rotate(-45deg) scaleX(1.24138);
}

.o-hamburger.is-active .o-hamburger__bar:nth-child(4) {
  transform: scaleX(0) translateY(-9px);
}
