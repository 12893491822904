/* ==========================================================================
   #PAGE
   ========================================================================== */

html {
  @include font-base;

  background-color: var(--main-bg-color);
  color: var(--main-text-color);
  font-family: $font-sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}
