.has-h-1-font-size {
  @include h1;
}

.has-h-2-font-size {
  @include h2;
}

.has-h-2-smaller-font-size {
  @include h2-smaller;
}

.has-h-3-font-size {
  @include h3;
}

.has-small-font-size {
  font-size: 13px;
}
