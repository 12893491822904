/* stylelint-disable */

.gutenberg-blocks .alignfull > *[class*="inner-container"] > :not(.alignfull):not(.alignleft):not(.alignright):not(.alignwide),
.gutenberg-blocks > *:not(.custom-width):not(.alignfull):not(.alignwide) {
  @include wrapper($wrapper-width-small);
}

.gutenberg-blocks > .alignwide {
  @include wrapper($wrapper-width);
}

/* stylelint-enable */

.gutenberg-blocks {
  position: relative;
}

:where(.gutenberg-blocks) a {
  color: var(--brand-color);
}
