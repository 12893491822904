@use 'sass:math';

/* ==========================================================================
   #WORK GALLERY
   ========================================================================== */

.c-work-gallery {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: calc(100% - 40px);
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
}

.c-work-gallery__gallery {
  width: 100%;
}

.c-work__gallery-nav {
  position: absolute;
  top: 50%;
  width: 44px;
  height: 44px;
  background-color: rgba(#000, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  margin-top: -22px;
  transition: opacity $transition-fast-in ease,
    visibility $transition-fast-in ease;
}

.c-work__gallery-nav--prev {
  margin-left: 40px;
  left: 0;
  transform: scaleX(-1);
}

.c-work__gallery-nav--next {
  margin-right: 40px;
  right: 0;
}

.c-work-gallery.is-first .c-work__gallery-nav--prev,
.c-work-gallery.is-last .c-work__gallery-nav--next {
  opacity: 0;
  visibility: hidden;
}

.c-work__gallery-caption {
  font-size: 16px;
  opacity: 0.5;
  color: var(--main-text-color);
  margin-top: 14px;
  flex-shrink: 1;
  flex-grow: 0;
}

.c-work-gallery__slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 72%;
  max-height: 100%;
  width: auto;
  height: auto;
  flex-grow: 1;
}

.c-work-gallery__img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: calc(100% - var(--caption-height, 6rem));
  max-width: 100%;
  transition: opacity $transition-fast-in ease;
}

.c-work-gallery__caption {
  margin-top: 14px;
  font-size: 16px;
  opacity: 0.5;
  transition: opacity $transition-fast-in ease;
}

.swiper-slide.is-prev {
  transform-origin: right center;
}

.swiper-slide.is-next {
  transform-origin: left center;
}

.swiper-slide:not(.swiper-slide-active) .c-work-gallery__img {
  opacity: 0.35;
}

.swiper-slide:not(.swiper-slide-active) .c-work-gallery__caption {
  opacity: 0;
}

@include bp(large) {
  .c-work__gallery-nav--prev {
    margin-left: 80px;
  }

  .c-work__gallery-nav--next {
    margin-right: 80px;
  }
}
