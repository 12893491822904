@use 'sass:math';

@mixin h-base {
  font-size: 28px;
  line-height: math.div(32, 28);
  letter-spacing: 0;
}

@mixin font-base {
  font-size: 18px;
  line-height: math.div(28, 18);
  // letter-spacing: -0.05em;

  @include bp(medium) {
    font-size: 22px;
    line-height: math.div(32, 22);
  }
}

@mixin font-menu {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.05em;

  @include bp(medium) {
    font-size: 18px;
  }
}

@mixin font-smaller {
  font-size: 16px;
  line-height: math.div(24, 18);
  letter-spacing: 0.03em;

  @include bp(medium) {
    font-size: 18px;
    letter-spacing: 0.02em;
  }
}

@mixin font-button {
  font-size: 18px;
  line-height: 1;
  font-weight: 700;
}

@mixin h1 {
  @include h-base;

  font-weight: 900;

  @include bp(medium) {
    font-size: 60px;
    line-height: math.div(72, 60);
  }
}

@mixin h2 {
  @include h-base;

  font-weight: 900;

  @include bp(medium) {
    font-size: 43px;
    line-height: math.div(64, 43);
  }
}

@mixin h2-smaller {
  @include h-base;

  font-weight: 900;

  @include bp(medium) {
    font-size: 32px;
    line-height: math.div(42, 32);
  }
}

@mixin h3 {
  @include font-base;

  font-size: 22px;
  line-height: math.div(32, 22);
  font-weight: 900;
}

@mixin h4 {
  @include font-base;

  font-weight: 900;
}

@mixin h5 {
  @include font-base;

  font-weight: 900;
}

@mixin h6 {
  @include font-base;

  font-weight: 900;
}
