@use 'sass:math';

/* ==========================================================================
   #PARTNERS
   ========================================================================== */

.c-partners {
  margin-top: 0;

  @include big-space-block;
}

.c-partners__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  gap: 40px;
}

.c-partners__item {
  position: relative;
  width: 100%;
  margin: 0;
  align-self: center;
  display: block;
}

.c-partners__item::before {
  content: '';
  display: block;
  padding-top: math.div(90, 157) * 100%;
}

.c-partners__item--no-image::before {
  display: none;
}

.c-partners__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@include bp(small) {
  .c-partners__items {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
