@use 'sass:math';

.wp-block-gallery.is-style-slideshow {
  z-index: 4;
}

.wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected) {
  display: block;
  position: relative;
  padding-top: #{math.div(600, 1680) * 100%};
  width: 100%;
  background-color: var(--main-bg-color);
}

.wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected)
  .wp-block-image {
  transition: opacity $transition-very-slow-out ease,
    visibility $transition-very-slow-out ease;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}

.wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected)
  .wp-block-image.is-active,
.wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected):not(.js-processed)
  .wp-block-image:first-child {
  opacity: 1;
  visibility: visible;
  transition-duration: $transition-very-slow-in, $transition-very-slow-in;
  margin: 0;
}

.wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected)
  .wp-block-image
  img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}

@include bp-down(medium) {
  .wp-block-gallery.is-style-slideshow:not(.has-child-selected):not(.is-selected) {
    padding-top: #{math.div(1200, 1680) * 100%};
  }
}
