.c-sidebar-navigation > *:first-child {
  padding-bottom: 50px;
}

@include bp-down(xlarge) {
  /* stylelint-disable-next-line */
  html.is-submenu-fixed:not(.is-submenu-open) .c-sidebar-navigation {
    pointer-events: none;
  }

  /* stylelint-disable-next-line */
  html.is-submenu-fixed .c-sidebar-navigation > * {
    position: fixed;
    top: calc(var(--header-height) + var(--admin-bar-height));
    left: 0;
    width: 100%;
    max-height: calc(100vh - var(--header-height) - var(--admin-bar-height));
    max-width: none;
    z-index: 10;
    padding: $wrapper-padding-small;
    transition: visibility $transition-fast-in, opacity $transition-fast-in;
    opacity: 0;
    visibility: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--main-bg-color);
  }

  /* stylelint-disable-next-line */
  html.is-submenu-open .c-sidebar-navigation > * {
    opacity: 1;
    visibility: visible;
    flex-wrap: nowrap;
  }

  /* stylelint-disable-next-line */
  html.is-submenu-fixed .c-sidebar-navigation::after {
    display: block;
    content: '';
    position: fixed;
    left: 0;
    top: calc(var(--header-height) + var(--admin-bar-height));
    width: 100%;
    height: calc(100vh - var(--header-height) - var(--admin-bar-height));
    opacity: 0;
    background-color: var(--brand-color);
    transition: visibility $transition-fast-in, opacity $transition-fast-in;
  }

  /* stylelint-disable-next-line */
  html.is-submenu-open .c-sidebar-navigation::after {
    opacity: 0.5;
  }
}

@include bp(xlarge) {
  .c-sidebar-navigation {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    z-index: 2;
  }

  .gutenberg-blocks
    .c-sidebar-navigation.c-sidebar-navigation.c-sidebar-navigation {
    @include wrapper-right($wrapper-width);

    width: calc(
      (100% - #{$wrapper-width-small}) / 2 - #{2 * $wrapper-padding-small}
    );
  }

  .c-sidebar-navigation > * {
    position: sticky;
    top: calc(var(--header-height) + var(--admin-bar-height) + 40px);
    margin-top: 100px;
    left: 0;
    pointer-events: auto;
  }
}

@include bp($wrapper-width + 2 * $wrapper-padding-small) {
  .gutenberg-blocks
    .c-sidebar-navigation.c-sidebar-navigation.c-sidebar-navigation {
    width: #{($wrapper-width - $wrapper-width-small) * 0.5 - $wrapper-padding-small};
  }
}
