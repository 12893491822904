@use 'sass:math';

/* ==========================================================================
   #RESOURCE TILE
   ========================================================================== */

.c-resource-tile {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  transition: color 0.3s ease, background-color 0.3s ease;
  position: relative;
}

.c-resource-tile::before {
  content: '';
  display: block;
  position: absolute;
  left: -16px;
  top: -16px;
  right: -16px;
  bottom: -16px;
  transition: opacity 0.3s ease;
  z-index: -1;
  opacity: 0;
  background-color: var(--brand-color);
}

.c-resource-tile__edition {
  opacity: 0.5;
}

.c-resource-tile__icon {
  margin-right: 6px;
  width: 19px;
}

.c-resource-tile__icon--pdf {
  width: 24px;
}

.c-resource-tile__meta {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
}

.c-resource-tile__type {
  display: flex;
  align-items: center;
  line-height: 1;
}

.c-resource-tile:hover {
  color: var(--person-text-color);
}

.c-resource-tile:hover .c-resource-tile__title {
  color: inherit;
}

.c-resource-tile:hover::before {
  opacity: 1;
}
