:root {
  --full-height: 100vh;
  --initial-height: 100vh;
  --scroll-width: 0px; // stylelint-disable-line
  --admin-bar-height: 0px; // stylelint-disable-line
  --header-height: 0px; // stylelint-disable-line
  --main-bg-color: #{$color-white};
  --main-text-color: #{$color-text};
  --alt-text-color: #{$color-white};
  --brand-color: #{$color-brand};
  --brand-darker-color: #{$color-brand-darker};
  --brand-darkest-color: #{$color-brand-darkest};
  --header-bg-color: #{$color-white};
  --footer-bg-color: #{$color-dark};
  --notification-text-color: #{$color-white};
  --person-bg-color: #{$color-brand};
  --person-text-color: #{$color-white};
}
