@use 'sass:math';

/* ==========================================================================
   #ANNOTATION MARK
   ========================================================================== */

.c-annotation-mark > a {
  position: relative;
}

.c-annotation-mark > a::before {
  content: '';
  position: absolute;
  inset: -0.5em -0.75em;
}

.c-annotation-mark__popup {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--brand-color);
  color: var(--person-text-color);
  padding: 1em;
  display: flex;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
/* stylelint-disable selector-no-qualifying-type */
.c-annotation-mark.is-open .c-annotation-mark__popup,
html.no-touch .c-annotation-mark:hover .c-annotation-mark__popup {
  opacity: 1;
  visibility: visible;
}
/* stylelint-enable selector-no-qualifying-type */

.c-annotation-mark__popup a {
  color: inherit;
  text-decoration: underline;
}

/* stylelint-disable-next-line selector-no-qualifying-type */
html.no-touch .c-annotation-mark > a {
  cursor: default;
}

.c-annotation-mark__counter {
  margin-right: 0.5em;
}

.c-annotation-mark__close {
  margin: -10px -10px -10px auto;
  width: calc(1em + 20px);
  height: calc(1em + 20px);
  position: relative;
  border: 10px solid transparent;
}

.c-annotation-mark__close::before,
.c-annotation-mark__close::after {
  content: '';
  width: 1px;
  height: 1em;
  display: block;
  position: absolute;
  left: 50%;
  top: 0;
  background-color: currentColor;
}

.c-annotation-mark__close::before {
  transform: rotate(-45deg);
}

.c-annotation-mark__close::after {
  transform: rotate(45deg);
}

@include bp(large) {
  .c-annotation-mark {
    position: relative;
  }

  .c-annotation-mark__popup {
    position: absolute;
    top: 100%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-left: -0.5em;
    width: 360px;
    max-width: 90vw;
    border: 0.5em solid transparent;
    background-clip: padding-box;
  }

  .c-annotation-mark__close {
    display: none;
  }

  .c-annotation-mark--right .c-annotation-mark__popup {
    right: 50%;
    left: auto;
    margin-left: auto;
    margin-right: -0.5em;
  }
}
