@use 'sass:math';

/* ==========================================================================
   #WORKS GALLERY
   ========================================================================== */

.c-works-gallery {
  display: grid;
  gap: 10px;
  flex-wrap: wrap;
  font-size: 12px;
  line-height: 1.2;
  grid-template-columns: 1fr 1fr;
}

.c-works-gallery__item {
  position: relative;
}

.c-works-gallery__item::before {
  content: '';
  display: block;
  padding-top: math.div(128, 255) * 100%;
}

.c-works-gallery__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-works-gallery__overlay {
  display: flex;
  flex-direction: column;
  padding: 13px;
  background-color: var(--person-bg-color);
  color: var(--person-text-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity $transition-fast-in ease,
    visibility $transition-fast-in ease;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  text-align: left;
  opacity: 0;
  visibility: hidden;
}

.c-works-gallery__item:hover .c-works-gallery__overlay {
  opacity: 1;
  visibility: visible;
}

.c-works-gallery__author {
  margin-top: auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
}

.c-works-gallery__title {
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  /* stylelint-enable */
  overflow: hidden;
}

.c-works-gallery__award {
  position: absolute;
  right: -10px;
  top: -10px;
}

@include bp-down(medium) {
  .c-works-gallery {
    line-height: 1.2;
  }
}

@include bp(medium) {
  .c-works-gallery {
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 16px;
    gap: 20px;
  }
}

@include bp(large) {
  .c-works-gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@include bp(xlarge) {
  .c-works-gallery {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
