/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1,
.u-h1 {
  @include h1;
}

h2,
.u-h2 {
  @include h2;
}

.u-h2-smaller {
  @include h2-smaller;
}

h3,
.u-h3 {
  @include h3;
}

h4,
.u-h4 {
  @include h4;
}

h5,
.u-h5 {
  @include h5;
}

h6,
.u-h6 {
  @include h6;
}
