.wp-block-image img {
  width: auto;
  height: auto;
}

.wp-block-image.wp-block-image figcaption {
  text-align: left;
  color: inherit;
  opacity: 0.5;
}

.wp-block-image[class*='is-style-award-'] {
  position: relative;
}

.wp-block-image[class*='is-style-award-']:not(.is-selected)::after,
.wp-block-image[class*='is-style-award-']::before {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 50%;
  color: var(--person-text-color);
  background-color: var(--person-bg-color);
  width: 88px;
  height: 88px;
  margin-right: -44px;
  margin-top: -44px;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 900;
  line-height: 1;
  white-space: nowrap;
  font-size: 18px;
  left: auto;
  z-index: 1;
  content: '';
}

.has-award-badge {
  position: relative;
}

.has-award-badge .c-work-award {
  position: absolute;
  width: 64px;
  height: 64px;
  right: -12px;
  top: -12px;
  font-size: 16px;

  svg {
    width: 50%;
    height: 50%;
  }

  @include bp(xlarge) {
    width: 88px;
    height: 88px;
    right: -44px;
    top: -44px;
    font-size: 20px;
  }
}

.has-award-badge .c-work-award__icon {
  display: none;
}

.has-award-badge .c-work-award__text {
  display: inline;
}

/*
.wp-block-image.is-style-award-1st:not(.is-selected)::after {
  content: 'st';
  text-indent: 0.9em;
  padding-bottom: 0.6em;
}

.wp-block-image.is-style-award-2nd:not(.is-selected)::after {
  content: 'nd';
  text-indent: 1.2em;
  padding-bottom: 0.6em;
}

.wp-block-image.is-style-award-3rd:not(.is-selected)::after {
  content: 'rd';
  text-indent: 1em;
  padding-bottom: 0.6em;
}

.wp-block-image.is-style-award-1st::before,
.wp-block-image.is-style-award-2nd::before,
.wp-block-image.is-style-award-3rd::before {
  font-size: 43px;
}

.wp-block-image.is-style-award-1st::before {
  content: '1';
  text-indent: -0.5em;
}

.wp-block-image.is-style-award-2nd::before {
  content: '2';
  text-indent: -0.6em;
}

.wp-block-image.is-style-award-3rd::before {
  content: '3';
  text-indent: -0.6em;
}

.wp-block-image.is-style-award-mention::before {
  content: 'Mention';
}

.wp-block-image.is-style-award-special::before {
  content: 'Special';
}
*/

.wp-block-image__gallery-trigger {
  position: relative;
}

.wp-block-image__gallery-trigger-button {
  position: absolute;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--person-bg-color);
  color: var(--person-text-color);
  padding: 0 16px 19px;
  font-weight: 700;
  transition: opacity $transition-fast-in ease,
    visibility $transition-fast-in ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  text-align: left;
}

.wp-block-image__gallery-trigger:hover .wp-block-image__gallery-trigger-button {
  opacity: 1;
  visibility: visible;
}

.wp-block-image__gallery-trigger-button .wp-block-image__gallery-icon {
  margin-left: 14px;
}

.wp-block-image__gallery-trigger > .wp-block-image__gallery-icon {
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: 18px;
  color: var(--brand-color);
  transition: opacity $transition-fast-in ease,
    visibility $transition-fast-in ease;
}

.wp-block-image__gallery-trigger:hover > .wp-block-image__gallery-icon {
  opacity: 0;
  visibility: hidden;
}

@include bp-down(medium) {
  .wp-block-image__gallery-trigger > .wp-block-image__gallery-icon {
    bottom: auto;
    top: 100%;
    left: auto;
    right: 0;
    margin-top: 16px;
  }

  .wp-block-image--trigger figcaption {
    padding-right: 30px;
  }

  .wp-block-image__gallery-trigger:last-child {
    margin-bottom: calc(1rem + 30px);
  }

  .c-work__meta {
    text-align: right;
  }

  /* stylelint-disable-next-line */
  body.single-work .c-work__header + .wp-block-image[class*='is-style-award-'] {
    margin-top: 50px;
  }
}
