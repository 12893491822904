@mixin wrapper-base {
  width: calc(100% - #{2 * $wrapper-padding-small});
  margin-left: auto;
  margin-right: auto;
}

@mixin wrapper($width) {
  @include wrapper-base;

  max-width: $width;
}

@mixin wrapper-right($width) {
  margin-right: $wrapper-padding-small;
  margin-left: $wrapper-padding-small;
  max-width: $width;

  @media (min-width: #{$width + 2 * $wrapper-padding-small}) {
    margin-left: calc((100% - #{$width}) / 2);
    max-width: none;
  }
}

@mixin wrapper-left($width) {
  margin-right: $wrapper-padding-small;
  margin-left: $wrapper-padding-small;
  max-width: $width;

  @media (min-width: #{$width + 2 * $wrapper-padding-small}) {
    margin-right: calc((100% - #{$width}) / 2);
    max-width: none;
  }
}
