/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.o-checkbox__input {
  @include checkbox;

  vertical-align: -60%;
}

.o-checkbox.is-invalid {
  color: var(--form-error-color);
}

.o-checkbox:not(.is-invalid) .o-input__error {
  display: none;
}
