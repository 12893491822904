// ==========================================================================
// #GLOBAL
// ==========================================================================
// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

/* Fonts
   ========================================================================== */

$font-sans-serif: ff-scala-sans-pro, sans-serif !default;

/* Breakpoints
   ========================================================================== */

$breakpoints: (
  small: 480px,
  medium: 720px,
  large: 960px,
  xlarge: 1200px,
  full: 1400px,
);

/* Colors
   ========================================================================== */

// Colors shouldn't be used directly but reassigned
$color-white: #fff;
$color-black: #000;
$color-dark: #1d1b1c;
$color-orange: #fe6f17;
$color-orange-darker: #f55d00;
$color-orange-darkest: #d14900;
$color-warning: #d14646;

$color-bg: $color-white;
$color-text: $color-dark;

$color-brand: $color-orange;
$color-brand-darker: $color-orange-darker;
$color-brand-darkest: $color-orange-darkest;

/* Sizes
   ========================================================================== */

$wrapper-padding-small: 20px;
$wrapper-width: 1450px;
$wrapper-width-small: 700px;

/* Transitions
   ========================================================================== */

$transition-slow-in: 0.5s;
$transition-very-slow-in: 1s;
$transition-fast-in: 0.3s;

$transition-slow-out: $transition-slow-in * 0.75;
$transition-very-slow-out: $transition-very-slow-in * 0.75;
$transition-fast-out: $transition-fast-in * 0.75;
