@use 'sass:math';

/* ==========================================================================
   #ACCORDION
   ========================================================================== */

.c-accordion {
  @include big-space-block;
}

.c-accordion__item {
  border: 2px solid var(--brand-darkest-color);
  margin-bottom: 30px;
}

.c-accordion__item-content-wrapper {
  $padding: math.div(30, 22) * 1em;

  max-height: 0;
  overflow: hidden;
  transition: max-height $transition-fast-in;
  padding-left: $padding;
  padding-right: $padding;
}

.c-accordion__item.is-active .c-accordion__item-content-wrapper {
  max-height: 600px;
}

html.js .c-accordion__item.is-active .c-accordion__item-content-wrapper { /* stylelint-disable-line */
  max-height: none;
}

.c-accordion__item-content {
  $padding: math.div(30, 18) * 1em;

  padding-top: $padding;
  padding-bottom: $padding;
}

.c-accordion__item-content > *:last-child {
  margin-bottom: 0;
}

.c-accordion__item-title {
  $padding: math.div(30, 22) * 1em;
  $padding-bottom: math.div(27, 22) * 1em;

  color: var(--brand-darkest-color);
  font-weight: 700;
  padding: $padding $padding $padding-bottom $padding;
  display: flex;
  justify-content: space-between;
  font-size: inherit;
  width: 100%;
  text-align: left;
  align-items: center;
}

.c-accordion__item-icon {
  display: block;
  width: (math.div(26, 22) * 1em);
  height: (math.div(26, 22) * 1em);
  margin-top: -3px;
  margin-left: 10px;
  margin-right: 0;
  position: relative;
  transition: transform $transition-fast-in;
}

.c-accordion__item-icon::after,
.c-accordion__item-icon::before {
  content: '';
  width: 100%;
  height: 0;
  display: block;
  border-top: 1px solid currentColor;
  border-bottom: 1px solid currentColor;
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
}

.c-accordion__item-icon::after {
  transform: rotate(90deg);
}

.c-accordion__item.is-active .c-accordion__item-icon {
  transform: rotate(45deg);
}
