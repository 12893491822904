.wp-block-buttons[class*='wp-container-'] {
  align-items: flex-start;
}

.wp-block-button {
  font-weight: 700;
}

.wp-block-buttons.wp-block-buttons > .wp-block-button {
  margin-bottom: 15px;
}

.wp-block-button .wp-block-button__link.wp-block-button__link {
  font-size: inherit;
  letter-spacing: 0;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  line-height: 1;
  color: inherit;
  text-align: left;
}

.wp-block-button.is-style-primary .wp-block-button__link {
  @include button-primary;
}

.wp-block-button.is-style-secondary .wp-block-button__link {
  @include button-secondary;
}

.wp-block-button[class*='is-style']:not(.is-style-default)
  .wp-block-button__link {
  font-size: 18px;
}

.wp-block-button:not([class*='is-style']) .wp-block-button__link:hover,
.wp-block-button.is-style-default .wp-block-button__link:hover {
  color: var(--brand-color);
}

@include bp-down(small) {
  .is-style-navigation .is-style-secondary .wp-block-button__link,
  .is-style-navigation .is-style-primary .wp-block-button__link {
    width: 100%;
  }
}
