/* ==========================================================================
   #TEXT
   ========================================================================== */

.u-text-smallest {
  font-size: 14px;
}

.u-text-small {
  font-size: 16px;
}

.u-text-smaller {
  @include font-smaller;
}

.u-text-menu {
  @include font-menu;
}

.u-text-form {
  font-size: 18px;
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-text-center {
  text-align: center;
}

.u-fw-bold {
  font-weight: bold;
}

.u-fw-black {
  font-weight: 900;
}
