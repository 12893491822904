/* ==========================================================================
   #ASIDE IMAGE
   ========================================================================== */

.c-aside-image {
  position: relative;
  pointer-events: none;
}

.c-aside-image__inner {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  direction: rtl;
  padding-right: var(--image-gap, 40px);
}

.c-aside-image.is-style-right .c-aside-image__inner {
  direction: ltr;
  right: 0;
  left: auto;
  padding-right: 0;
  padding-left: var(--image-gap, 40px);
}

.c-aside-image__image {
  display: block;
  width: auto;
  height: auto;
  max-width: none;
  transition: opacity $transition-fast-in ease;
}

.c-aside-image__image.is-dimmed {
  opacity: 0.45;
}

@include bp-down(large) {
  .c-aside-image {
    display: none;
  }
}

@include bp(large) {
  .c-aside-image__inner {
    width: calc((100% - #{$wrapper-width-small}) / 2);
  }
}
