/* ==========================================================================
   #FILTER GRID
   ========================================================================== */

.o-filter-grid {
  @include wrapper(828px);

  display: grid;
  flex-direction: column;
  grid-auto-columns: auto;
  gap: 32px;
}

@include bp(medium) {
  .o-filter-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .o-filter-grid--persons {
    gap: 64px;
  }
}

@include bp(large) {
  .o-filter-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
