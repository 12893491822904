/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include wrapper($wrapper-width);
}

.o-wrapper-small {
  @include wrapper($wrapper-width-small);
}

.o-wrapper-left {
  @include wrapper-left($wrapper-width);
}

.o-wrapper-right {
  @include wrapper-right($wrapper-width);
}

.o-wrapper-left-small {
  @include wrapper-left($wrapper-width-small);
}

.o-wrapper-right-small {
  @include wrapper-right($wrapper-width-small);
}
