@use 'sass:math';

.c-tiles {
  overflow-x: hidden;

  @include big-space-block;
}

.c-tiles__items {
  --padding-width: #{$wrapper-padding-small};

  display: flex;
  align-items: stretch;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.c-tiles__items::before,
.c-tiles__items::after {
  display: block;
  content: '';
  width: var(--padding-width);
  flex-shrink: 0;
  flex-grow: 0;
}

.c-tiles__item {
  width: calc(100% - 96px);
  max-width: 334px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;
  flex-shrink: 0;
  flex-grow: 0;
}

.c-tiles__item + .c-tiles__item {
  margin-left: 32px;
}

.c-tiles__item-edition {
  flex-grow: 0;
  flex-shrink: 0;
  text-align: right;
  padding-bottom: 5px;
}

.c-tiles__item-media {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
}

.c-tiles__item-media::before {
  content: '';
  padding-top: math.div(174, 330) * 100%;
  display: block;
  width: 100%;
}

.c-tiles__item-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.c-tiles__item-title {
  font-size: inherit;
  font-weight: inherit;
  padding: 11px 14px;
  margin: 0;
  background-color: var(--brand-color);
  color: var(--person-text-color);
  min-height: #{2 * (1.5 * 16 + 11)}px;
  line-height: 1.5;
}

.c-tiles__item-title-wrapper {
  display: box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  // stylelint-disable-next-line property-no-unknown
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

@include bp($wrapper-width-small + 2 * $wrapper-padding-small) {
  .c-tiles__items {
    --padding-width: calc(50% - #{math.div($wrapper-width-small, 2)});
  }
}
