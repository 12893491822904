/* ==========================================================================
   #HEADER
   ========================================================================== */

html.js .c-header { /* stylelint-disable-line */
  height: var(--header-height);
}

.c-header__inner {
  background-color: var(--header-bg-color);
  position: relative;
}

.c-header--database .c-header__inner,
.c-header--reduced .c-header__inner {
  background-color: var(--main-bg-color);
}

html.js .c-header__inner { /* stylelint-disable-line */
  position: fixed;
  top: var(--admin-bar-height);
  left: 0;
  width: 100%;
  z-index: 10;
}

.c-header__message {
  background-color: var(--brand-color);
  color: var(--notification-text-color, var(--alt-text-color));
  padding: 20px 0;
  display: none;
}

html.has-header-message .c-header__message { // stylelint-disable-line
  display: block;
}

.c-header__message-inner {
  position: relative;
  padding-right: 30px;
}

.c-header__message-button {
  position: absolute;
  right: 0;
  top: 0;
}

.c-header__logo {
  display: block;
  order: 1;
  transition: transform $transition-fast-in, color $transition-fast-in;
  transform-origin: right center;
}

.c-header--database .c-header__logo,
.c-header--reduced .c-header__logo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.c-header__logo-img {
  width: 94px;
  height: 36px;
  display: block;
}

.c-header__nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px 0 15px;
}

.c-header--database .c-header__nav,
.c-header--reduced .c-header__nav {
  justify-content: flex-end;
}

.c-header__menu-trigger {
  margin-right: 30px;
  margin-left: -(29px + 30px);
  transform: translateX((29px + 30px));
  order: -1;
  transition: visibility $transition-fast-in, opacity $transition-fast-in;
  opacity: 0;
  visibility: hidden;
}

.c-header__blog-description {
  order: 2;
  margin-left: 17px;
  $offset: #{17 + 94 + 35}px;
  max-width: calc(100% - #{$offset}); /* stylelint-disable-line */
  transition: transform $transition-fast-in;
}

.c-header__menu {
  order: 4;
  margin-top: 10px;
  width: 100%;
}

.c-header__menu-nav {
  list-style: none;
  margin: 0 0 0 -20px;
  padding: 0;
  display: flex;
}

.c-header__menu-item {
  margin: 15px 0 0 20px;
}

.c-header__menu-item.current-menu-item {
  color: var(--brand-color);
}

.c-header__lang {
  font-size: 16px;
  display: flex;
  order: 3;
  line-height: 35px;
  margin-left: auto;
  transition: opacity $transition-fast-in, visibility $transition-fast-in;
}

.c-header--database .c-header__lang,
.c-header--reduced .c-header__lang {
  margin-left: 0;
}

.c-header__lang-link {
  width: 35px;
  height: 35px;
}

.c-header__search-trigger,
.c-header__close {
  order: 3;
  align-self: center;
  margin-left: 35px;
}

.c-header__search-trigger-icon,
.c-header__close-icon {
  display: block;
}

.c-header__title {
  letter-spacing: 0.01em;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: auto;
}

@include bp(medium) {
  .c-header__menu-nav {
    justify-content: flex-start;
    margin-left: -40px;
  }

  .c-header__menu-item {
    margin-left: 40px;
  }

  .c-header__lang {
    margin-left: auto;
  }

  .c-header__search-trigger,
  .c-header__close {
    margin-left: 60px;
  }
}

@include bp-down(large) {
  .c-header__blog-description.c-header__blog-description {
    font-size: 12px;
  }

  .c-header__lang-link.is-active {
    display: none;
  }

  .c-header__title {
    display: none;
  }
}

@include bp-down(xlarge) {
  html.is-submenu-fixed .c-header__menu-trigger { /* stylelint-disable-line */
    opacity: 1;
    visibility: visible;
  }

  html.is-submenu-fixed .c-header__logo { /* stylelint-disable-line */
    transform: scale(0.66) translateX(35px);
  }

  html.is-submenu-fixed .c-header__blog-description { /* stylelint-disable-line */
    transform: translateX(35px);
  }

  html.is-submenu-fixed .c-header__lang { /* stylelint-disable-line */
    opacity: 0;
    visibility: hidden;
  }
}

@include bp(xlarge) {
  .c-header__menu {
    order: 2;
    width: auto;
    margin-top: 0;
  }

  .c-header__menu-nav {
    margin-left: 0;
  }

  .c-header__menu-item {
    margin-top: 0;
  }

  .c-header__blog-description.c-header__blog-description {
    margin-left: auto;
    font-size: 14px;
  }

  .c-header__lang {
    margin-left: 60px;
  }

  .c-header__menu-trigger {
    display: none;
  }
}
