/* ==========================================================================
   #INPUT
   ========================================================================== */

.o-input {
  display: block;
}

.o-input__label {
  margin-bottom: 7px;
  display: block;
}

.o-input__input {
  display: block;
  width: 100%;
  padding: 18px 20px 14px;
  color: var(--form-input-typography-color);
  background-color: var(--form-input-normal-background-color);
}

.o-input__input::placeholder {
  opacity: 0.5;
}

.o-input__input:focus::placeholder {
  opacity: 0;
}

.o-input__input:disabled {
  opacity: 0.5;
}

.o-input__input:focus {
  background-color: var(--form-input-focus-background-color);
  color: var(--form-focus-color);
  outline: 2px solid var(--brand-color);
}

.o-input.is-invalid:not(:focus-within) .o-input__input {
  background-color: var(--form-error-input-background-color);
  color: var(--form-error-color);
}

.o-input:not(.is-invalid) .o-input__error {
  display: none;
}

/* stylelint-disable no-descending-specificity, no-duplicate-selectors */
.o-input__input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px var(--form-input-focus-background-color) inset !important;
  }

  &:-webkit-autofill {
    -webkit-text-fill-color: var(--form-focus-color) !important;
  }
}
/* stylelint-enable no-descending-specificity, no-duplicate-selectors */
