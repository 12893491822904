@use 'sass:math';

/* ==========================================================================
   #PERSON
   ========================================================================== */

.c-person {
  padding: 40px 0;
}

.c-person__meta {
  margin-bottom: 2em;
}

.c-person__photo {
  display: block;
  margin-bottom: 1.5em;
}

@include bp(xlarge) {
  .c-person__header {
    margin-bottom: 50px;
    position: relative;
  }

  .c-person__header .c-person__meta.c-person__meta.c-person__meta {
    position: absolute;
    right: 0;
    top: 0;
    width: calc((100% - #{$wrapper-width-small}) / 2);
    padding-left: var(--image-gap, 40px);
  }
}
