@use 'sass:math';

/* ==========================================================================
   #AUTHOR BOX
   ========================================================================== */

.c-author-box {
  display: flex;
  flex-wrap: wrap;
}

.c-author-box__photo {
  border-radius: 50%;
  display: block;
  width: 128px;
  height: 128px;
  margin-right: 44px;
}

.c-author-box__social {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}

.c-author-box__social--full {
  justify-content: space-between;
}

.c-author-box__social-link {
  margin-bottom: 22px;
}

.c-author-box__social--full .c-author-box__social-link {
  margin-bottom: 0;
}

.c-author-box__name,
.c-author-box__description {
  width: 100%;
}

.c-author-box__name {
  margin-top: 25px;
  font-size: inherit;
  font-weight: inherit;
  margin-bottom: 0;
}

@include bp(xlarge) {
  .c-author-box__social {
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
    order: 1;
  }

  .c-author-box__social--full {
    justify-content: space-between;
  }
}
