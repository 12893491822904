/// * ========================================================================
//   #INPUTS
//   ======================================================================== */

@mixin checkbox {
  appearance: none;
  border: 2px solid currentColor;
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 13px;
  transition: background-color $transition-fast-in ease,
    border-color $transition-fast-in ease, color $transition-fast-in ease;

  &:checked {
    background-color: currentColor;
    color: var(--brand-color);
  }

  &::after {
    content: '';
    display: block;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.28' height='21.279'%3E%3Cpath d='m.852 13.522 5.81 6.042L20.557 6.236' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E")
      no-repeat center center/contain;
    width: 100%;
    height: 21px;
    opacity: 0;
    transition: opacity $transition-fast-in ease;
  }

  &:checked::after {
    opacity: 1;
  }
}
