/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

.o-btn {
  @include font-button;

  font-family: inherit;
}

/* Style variants
   ========================================================================== */

.o-btn--primary {
  @include button-primary;
}

.o-btn--secondary {
  @include button-secondary;
}
