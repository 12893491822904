@use 'sass:math';

/* ==========================================================================
   #GOOGLE MAPS
   ========================================================================== */

.c-google-maps {
  $height: math.div(676, 1680) * 100%;

  width: 100%;
  padding-top: $height;
  position: relative;
  max-width: none;
  z-index: 4;
}

.c-google-maps__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.c-google-maps__info-window {
  color: $color-dark;
}

.c-google-maps__info-window :last-child {
  margin-bottom: 0;
}

.c-google-maps .c-google-maps__info-window.c-google-maps__info-window {
  @include font-base;

  font-family: $font-sans-serif;
  padding: 20px 10px 5px 20px;
}

.gm-style .gm-style-iw-c.gm-style-iw-c {
  border-radius: 0;
  box-shadow: 0 3px 30px rgba(#000, 0.2);
  padding: 0;
}

.gm-style .gm-style-iw-d.gm-style-iw-d {
  padding: 0;
}

@include bp-down(medium) {
  .c-google-maps {
    padding-top: 80%;
  }
}
